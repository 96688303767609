var render = function render() {
  var _vm$assignHistory2;
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "user-limit-dialog custom-messagge-dialog",
    attrs: {
      visible: _vm.isShowDialog,
      "close-on-click-modal": false,
      "destroy-on-close": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShowDialog = $event;
      },
      closed: _vm.resetConfig
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("Account42")) + "\n      "), _vm.can_assign_gpu ? _c("span", {
    staticClass: "sub"
  }, [_vm._v("(" + _vm._s(_vm.remainingTimeFormat(_vm.can_assign_gpu, "DHM")) + ")")]) : _vm._e()])]), _vm._v(" "), _c("div", {
    staticClass: "sub-line"
  }, [_c("i", {
    staticClass: "el-icon-warning"
  }), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.$t("Account44")))])]), _vm._v(" "), _c("el-form", {
    ref: "form",
    staticClass: "custom-form",
    attrs: {
      "label-position": "top",
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "100%"
    }
  }, [_vm._l(_vm.assignHistory, function (item, index) {
    var _vm$assignHistory;
    return _c("el-form-item", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: (_vm$assignHistory = _vm.assignHistory) === null || _vm$assignHistory === void 0 ? void 0 : _vm$assignHistory.length,
        expression: "assignHistory?.length"
      }],
      key: index,
      attrs: {
        label: "".concat(_vm.$t("Account45"), " (").concat(_vm.$t("Hours"), ")")
      }
    }, [_c("template", {
      slot: "label"
    }, [_c("div", {
      staticClass: "title flex"
    }, [_c("span", [_vm._v(_vm._s("".concat(_vm.$t("Account45"), " (").concat(_vm.$t("Hours"), ")")))]), _vm._v(" "), _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index === 0,
        expression: "index === 0"
      }],
      staticClass: "btn",
      class: {
        disabled: _vm.isShowInput || _vm.notEnoughGpu
      },
      on: {
        click: _vm.addNewItem
      }
    }, [_c("i", {
      staticClass: "el-icon-circle-plus-outline"
    }), _vm._v(" " + _vm._s(_vm.$t("newAdd")) + "\n          ")])])]), _vm._v(" "), _c("el-input-number", {
      staticClass: "custom-input-number",
      attrs: {
        disabled: true,
        placeholder: _vm.$t("inputPls", {
          type: _vm.$t("Hours")
        }),
        controls: false
      },
      model: {
        value: item.gpu,
        callback: function callback($$v) {
          _vm.$set(item, "gpu", $$v);
        },
        expression: "item.gpu"
      }
    })], 2);
  }), _vm._v(" "), _vm.isShowInput ? _c("el-form-item", {
    attrs: {
      prop: "limit"
    }
  }, [_c("template", {
    slot: "label"
  }, [_c("div", {
    staticClass: "title flex"
  }, [_c("span", [_vm._v(_vm._s("".concat(_vm.$t("Account45"), " (").concat(_vm.$t("Hours"), ")")))]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$assignHistory2 = _vm.assignHistory) !== null && _vm$assignHistory2 !== void 0 && _vm$assignHistory2.length),
      expression: "!assignHistory?.length"
    }],
    staticClass: "btn",
    class: {
      disabled: _vm.isShowInput || _vm.notEnoughGpu
    },
    on: {
      click: _vm.addNewItem
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-plus-outline"
  }), _vm._v(" " + _vm._s(_vm.$t("newAdd")) + "\n          ")])])]), _vm._v(" "), _c("el-input-number", {
    staticClass: "custom-input-number",
    attrs: {
      placeholder: _vm.$t("inputPls", {
        type: _vm.$t("Hours")
      }),
      controls: false,
      min: 0,
      max: 9999999999,
      step: 0.1,
      precision: 1
    },
    model: {
      value: _vm.formData.limit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "limit", $$v);
      },
      expression: "formData.limit"
    }
  })], 2) : _vm._e()], 2), _vm._v(" "), _c("div", {
    staticClass: "btns",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("Confirm")))])], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };