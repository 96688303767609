function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import UserAvatar from '@/components/UserAvatar.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import SetUserPopup from '@/components/SetUserPopup.vue';
import PinyinMatch from 'pinyin-match'; // es
import { timestampToTime } from '@/utils/diffDate';
import { LANG } from '@/utils/common';
export default {
  components: {
    UserAvatar: UserAvatar,
    SetUserPopup: SetUserPopup
  },
  props: ['isCollapse', 'pathList'],
  data: function data() {
    console.log('LANG: ', LANG);
    return {
      searchKey: '',
      isFull: document.fullscreenElement || false,
      time: '-',
      langList: LANG
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState('user', ['userInfo'])), mapState('app', ['lang'])), mapGetters('user', ['isLoggedIn'])), {}, {
    localName: function localName() {
      var _path = this.$route.path;
      var localName = '';
      this.pathList.forEach(function (item) {
        var name = item.name,
          path = item.path,
          children = item.children;
        children && children.forEach(function (v) {
          if (v.path === _path) {
            localName = name + ' ＞ ' + v.name;
            return;
          }
        });
        if (path === _path) {
          localName = name;
        }
      });
      return localName;
    },
    fullIcon: function fullIcon() {
      if (this.isFull) {
        return 'haiyi haiyi-tuichuquanping';
      } else {
        return 'el-icon-full-screen';
      }
    }
  }),
  created: function created() {
    this.initTime();
    console.log(this.lang);
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions('user', ['getAccountInfo', 'updateAccountSettings', 'logout', 'setGuideStatus'])), mapActions('app', ['setLang'])), {}, {
    zoom: function zoom() {
      this.$emit('zoom', !this.isCollapse);
    },
    querySearch: function querySearch(text, cb) {
      var list = JSON.parse(JSON.stringify(this.pathList));
      cb(this.getPath(text, list));
    },
    getPath: function getPath(text, list) {
      var result = [];
      list.forEach(function (item) {
        var name = item.name,
          children = item.children;
        var isMatch = PinyinMatch.match(name, text);
        if (isMatch) {
          result = result.concat(children ? children : [item]);
        } else if (children) {
          result = result.concat(children.filter(function (v) {
            return PinyinMatch.match(v.name, text);
          }));
        }
      });
      if (!text) {
        result = list;
      }
      console.log('text:' + text, 'result: ', result);
      return result.map(function (v) {
        return _objectSpread({
          value: v.name
        }, v);
      });
    },
    changePath: function changePath(val) {
      this.$router.push(val.path);
    },
    handleEvent: function handleEvent(key) {
      switch (key) {
        case 'SET':
          this.showSetUserPopup();
          break;
        case 'PAS':
          this.showEditPasswordPopup();
          break;
        case 'OUT':
          this.logoutEvent();
          break;
        default:
          this.fullScreen();
      }
    },
    fullScreen: function fullScreen() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        document.documentElement.requestFullscreen();
        this.$message.success('您已进入全屏模式，退出全屏请按【ESC】退出');
      } else {
        document.exitFullscreen();
        this.$message.success('你已退出全屏模式');
      }
    },
    showSetUserPopup: function showSetUserPopup() {
      this.$refs.setUserPopu.show();
    },
    showEditPasswordPopup: function showEditPasswordPopup() {
      this.$refs.setUserPopu.show();
    },
    logoutEvent: function logoutEvent() {
      var _this = this;
      this.logout();
      this.$message({
        message: '注销成功',
        type: 'success'
      });
      var isSeaartMe = location.host.includes('seaart.me');
      var url = isSeaartMe ? 'https://www.seaart.me/' : 'https://www.seaart.ai/';
      window.location.replace(location.host.includes('e.seaart') ? url : 'https://aiart-openresty.dev.seaart.dev');
      // this.$router.push('/login');
      return;
      this.logout().then(function () {
        _this.$message({
          message: '注销成功',
          type: 'success'
        });
        _this.$router.push('/login');
      });
    },
    initTime: function initTime() {
      var _this2 = this;
      setInterval(function () {
        _this2.time = timestampToTime(new Date().getTime(), 'yyyy年MM月dd日 hh:mm:ss');
      }, 1000);
    },
    refreshPage: function refreshPage() {
      window.location.reload();
    },
    changeLang: function changeLang(key) {
      this.setLang(key);
      this.$i18n.locale = key;
    }
  })
};