import { render, staticRenderFns } from "./SetUserLimit.vue?vue&type=template&id=65816574&"
import script from "./SetUserLimit.vue?vue&type=script&lang=js&"
export * from "./SetUserLimit.vue?vue&type=script&lang=js&"
import style0 from "./SetUserLimit.vue?vue&type=style&index=0&id=65816574&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/s01-aiart-release/s01_aiart_release_aiart-web-openapi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65816574')) {
      api.createRecord('65816574', component.options)
    } else {
      api.reload('65816574', component.options)
    }
    module.hot.accept("./SetUserLimit.vue?vue&type=template&id=65816574&", function () {
      api.rerender('65816574', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/account/components/SetUserLimit.vue"
export default component.exports