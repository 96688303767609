//套餐类型
export var COMBO_TYPE = ['Purchase10', 'Purchase11', 'Purchase10'];
export var ERROR_CODE = {
  // 请求错误码解析
  20306: 'ErrorCode_20306',
  // 头像修改次数已达到限制
  80044: 'ErrorCode_80044',
  // 权益不足
  30005: 'ErrorCode_30005',
  // 上传的图片违规
  70000: 'ErrorCode_70000',
  // 操作失败
  70001: 'ErrorCode_70001',
  // 生成次数超过每日限制
  70002: 'ErrorCode_70002',
  // 任务等待队列已满
  30000: 'ErrorCode_30000',
  // 上传失败
  30001: 'ErrorCode_30001',
  // 文件上传超时
  30002: 'ErrorCode_30002',
  // 请求文件失败
  30003: 'ErrorCode_30003',
  // 文件大小超出限制
  30004: 'ErrorCode_30004',
  // 文件解析失败
  30008: 'ErrorCode_30008',
  // 模型图片数量必须大于1
  30009: 'ErrorCode_30009',
  // 模型名称重复
  30011: 'ErrorCode_30011',
  // 模型名称长度超出限制
  30029: 'ErrorCode_30029',
  // 导出xlsx数据失败
  30030: 'ErrorCode_30030',
  // 列表没数据
  10408: 'ErrorCode_10408',
  // 昵称不合法
  20302: 'ErrorCode_20302',
  // 昵称已存在
  20305: 'ErrorCode_20305',
  // 昵称修改次数已达到限制
  20001: 'ErrorCode_20001',
  // 账户不存在
  20004: 'ErrorCode_20004',
  // 您的邮箱已被禁用
  20005: 'ErrorCode_20005',
  // 密码错误
  20100: 'ErrorCode_20100',
  // 邮箱无效
  150001: 'ErrorCode_150001',
  // 未识别到人脸
  500601: 'ErrorCode_500601',
  // 此账号已是企业号, 不能添加
  500602: 'ErrorCode_500602',
  // 没有更多可分配资源
  500600: 'ErrorCode_500600',
  // 企业子账号数不足
  500603: 'ErrorCode_500603' // 账号已存在
};

export var WARNING_CODE = [70010];
export var PAY_RULE_LIST = {
  GPU: [{
    level: '基础',
    project: 'API,企业账号，QPS信息，图片存储',
    type: '累计合同金额（元）',
    rule: '2.7万以内',
    gift: '50个永久账号，80G试用存储（3月）',
    cost: '￥0.005/s'
  }, {
    level: '初级',
    project: 'API,企业账号，QPS信息，图片存储',
    type: '累计合同金额（元）',
    rule: '2.7万-10万',
    gift: '100个永久账号，500G试用存储（3月）',
    cost: '￥0.0048/s'
  }, {
    level: '专业',
    project: 'API,企业账号，QPS信息，图片存储',
    type: '累计合同金额（元）',
    rule: '10万以上',
    gift: '500个永久账号，1T试用存储（3月）',
    cost: '￥0.0045/s'
  }],
  IMG: [{
    level: '基础',
    project: 'API,企业账号，QPS信息，图片存储',
    type: '累计合同金额（元）',
    rule: '36.8万张以内',
    gift: '50个永久账号，80G试用存储（3月）',
    cost: '￥0.075/张'
  }, {
    level: '初级',
    project: 'API,企业账号，QPS信息，图片存储',
    type: '累计合同金额（元）',
    rule: '36.8万- 139.5万张',
    gift: '100个永久账号，500G试用存储（3月）',
    cost: '￥0.072/张'
  }, {
    level: '专业',
    project: 'API,企业账号，QPS信息，图片存储',
    type: '累计合同金额（元）',
    rule: '139.5万张以上',
    gift: '500个永久账号，1T试用存储（3月）',
    cost: '￥0.0675/张'
  }]
};
export var API_URL = {
  zhCn: 'https://a8zjqnaywn.feishu.cn/wiki/LFKEwoCioijNHNknIujcCVEanlb?from=from_copylink',
  zhTw: 'https://a8zjqnaywn.feishu.cn/wiki/LFKEwoCioijNHNknIujcCVEanlb?from=from_copylink',
  en: 'https://a8zjqnaywn.feishu.cn/wiki/Hvimw1SRPis3eWkwDrjcRrmUnmg'
};
export var LANG = {
  zhCn: '中文',
  zhTw: '繁體',
  en: 'English'
};
export var PAY_METHOD = [{
  type: 4,
  title: 'Renewal35',
  icon: 'alipay-icon.png'
}, {
  type: 5,
  title: 'Renewal37',
  icon: 'wechatPay.png'
},
// {
//   type: 6,
//   title: 'PayPal',
//   icon: 'paypal-icon.png',
// },
{
  type: 8,
  title: 'Stripe',
  icon: 'stripe-icon.png'
}, {
  type: 1,
  title: 'Payermax',
  icon: 'payermax-icon.png'
}];