import fetch from '@/utils/fetch';
var publicPath = '/api/v1/manager/';

/**
获取统计数据
*/
export function getAnalysisData(data) {
  return fetch({
    url: publicPath + 'daily/usage',
    method: 'post',
    data: data
  });
}

/**
  获取企业资源信息
*/
export function getCompanyUsageData(data) {
  return fetch({
    url: publicPath + 'usage',
    method: 'post',
    data: data
  });
}

/**
	获取企业可分配资源
*/
export function getCompanyCanAssignGpu(data) {
  return fetch({
    url: publicPath + 'emp/can-assign-gpu',
    method: 'post',
    data: data
  });
}

/**
	获取分配记录
*/
export function getAssignGpuLog(data) {
  return fetch({
    url: publicPath + 'emp/assign-gpu-log',
    method: 'post',
    data: data
  });
}