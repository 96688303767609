export default {
  ErrorCode_20306: '頭像修改次數已達到限制',
  ErrorCode_80044: '權益不足',
  ErrorCode_30005: '上傳的圖片違規',
  ErrorCode_70000: '操作失敗',
  ErrorCode_70001: '生成次數超過每日限制',
  ErrorCode_70002: '任務等待隊列已滿',
  ErrorCode_30000: '上傳失敗',
  ErrorCode_30001: '文件上傳超時',
  ErrorCode_30002: '請求文件失敗',
  ErrorCode_30003: '文件大小超出限制',
  ErrorCode_30004: '文件解析失敗',
  ErrorCode_30008: '模型圖片數量必須大於1',
  ErrorCode_30009: '模型名稱重復',
  ErrorCode_30011: '模型名稱長度超出限制',
  ErrorCode_30029: '導出xlsx數據失敗',
  ErrorCode_30030: '列表沒數據',
  ErrorCode_10408: '昵稱不合法',
  ErrorCode_20302: '昵稱已存在',
  ErrorCode_20305: '昵稱修改次數已達到限制',
  ErrorCode_20001: '賬戶不存在',
  ErrorCode_20004: '您的郵箱已被禁用',
  ErrorCode_20005: '密碼錯誤',
  ErrorCode_20100: '郵箱無效',
  ErrorCode_150001: '未識別到人臉',
  ErrorCode_500601: '此賬號已是企業號, 不能添加',
  ErrorCode_500602: '沒有更多可分配資源',
  ErrorCode_500600: '企業子賬號數不足',
  ErrorCode_500603: '賬號已存在',
  EnterpriseEdition: '企業版',
  Home: '首頁',
  AcconutManage: '賬號管理',
  Account: '賬號',
  Password: '密碼',
  Analysis: '數據分析',
  APIInfo: 'API信息',
  RenewalPackage: '續費套餐',
  Purchase: '購買記錄',
  PurchaseHistory: '購買歷史',
  Config: '開發配寘',
  Weclome: '歡迎',
  WeclomeTo: '來到海藝企業後臺',
  Search: '搜索',
  Reset: '重置',
  Status: '狀態',
  Handle: '操作',
  CreatedTime: '創建時間',
  Disabled: '禁用',
  Normal: '正常',
  Enable: '啟用',
  Selected: '已選',
  Page: '頁',
  Items: '條',
  Go: '前往',
  Confirm: '確認',
  Cancel: '取消',
  Prompt: '提示',
  Created: '創建',
  Add: '添加',
  HandleSuccess: '操作成功',
  HandleError: '操作失敗',
  ExitFullscreen: '退出全屏',
  FullScreen: '全屏窗口',
  ChangePassword: '修改密碼',
  SignOut: '退出後臺',
  CustomSuccess: '{type}成功',
  CustomError: '{type}失敗',
  Day: '天',
  Hours: '小時',
  Minute: '分鐘',
  Second: '秒',
  PayPal: '去PayPal支付',
  Payermax: '去Payermax支付',
  Account1: '用戶',
  Account2: '請輸入用戶昵稱',
  Account3: '第三方賬號',
  Account4: '請輸入第三方賬號',
  Account5: '創建時間',
  Account6: '請選擇開始時間',
  Account7: '請選擇結束時間',
  Account8: '批量新增',
  Account9: '批量導出',
  Account10: '用戶ID',
  Account11: '用戶昵稱',
  Account12: '用戶賬號',
  Account13: '累計消耗GPU(時)',
  Account14: '今日消耗GPU(時)',
  Account15: '累計使用存儲(MB)',
  Account16: '設為管理',
  Account17: '重置密碼',
  Account18: '新增用戶',
  Account19: '請輸入新增數量：(當前還可免費創建{num}個)',
  Account20: '請輸入創建賬號數',
  Account21: '創建賬號成功',
  Account22: '創建賬號失敗',
  Account23: '創建免費賬號超過限製，請充值！',
  Account24: '去充值',
  Account25: '密碼格式不正確',
  Account26: '是否{type}【{name}】的管理權限',
  Account27: '請輸入新密碼(賬號:【{name}】)',
  Account28: '新增數量輸入不正確',
  Account29: '請輸入創建數量',
  Account30: '每次最多生成500個賬號!',
  Account31: '新增子賬號後將自動導出子賬號，請妥善保存！',
  Account32: '取消管理',
  Account33: '設為管理',
  Account34: '確定{type}賬號:【{name}】?',
  Account35: '密碼長度為8~20個字符',
  Account36: '重置失敗，請重試',
  Account37: '每日GPU消耗限制',
  Account38: '只能輸入數字',
  Account39: '請輸入每日GPU消耗上限（分）',
  Account40: '新增指定子賬號',
  Account41: '當前還可免費創建{num}個',
  Account42: '分配GPU時長',
  Account43: '企業剩餘GPU:{text}',
  Account44: '分配GPU時長提交後將無法修改，請仔細填寫',
  Account45: '分配GPU',
  Account46: '初始密碼',
  Account47: 'GPU消耗限制',
  Email: '郵箱',
  Phone: '手機號',
  newAdd: '新增',
  inputPls: '請輸入{type}',
  inputWarning: '請輸入正確的{type}',
  PasswordANATCS: "密碼中不允許包含空格",
  InitPassword: '初始密碼',
  EmailPasswordHandler: '已存在的郵箱賬號不會覆蓋原密碼',
  Analysis1: '使用量統計',
  Analysis3: 'GPU累計使用時長',
  Analysis4: '含存儲/賬號換算的累計GPU消耗',
  Analysis5: '累計使用存儲',
  Analysis7: '剩余量統計',
  Analysis8: 'GPU剩余使用時長',
  Analysis9: '企業版海藝登錄和API調用累計總剩余量',
  Analysis11: '選擇查詢月份',
  Analysis12: '日均使用時長：',
  Analysis13: '日均使用存儲：',
  Analysis14: '每日使用',
  Analysis16: '時長',
  Analysis17: '存儲',
  Analysis18: '日',
  API1: '文檔正在更新中, 查看地址請點擊',
  API2: '這裏',
  Renewal1: 'GPU時長續費',
  Renewal2: '客戶等級',
  Renewal3: '贈送賬號',
  Renewal4: '贈送存儲',
  Renewal5: '3個月試用',
  Renewal6: '收費標準',
  Renewal7: '元/小時',
  Renewal77: '美元/小時',
  Renewal8: '購買GPU小時',
  Renewal9: '張',
  Renewal10: '贈送GPU小時',
  Renewal11: '小時',
  Renewal12: '續費套餐常見問題',
  Renewal13: '計算中',
  Renewal15: '立即購買',
  Renewal16: 'GPU{level}套餐{num}元/小時',
  Renewal166: 'GPU{level}套餐{num}美元/小時',
  Renewal17: '立即購買',
  Renewal18: '續費常見問題',
  Renewal19: '是否支持退款？',
  Renewal20: '不支持退款',
  Renewal21: '如果贈送存儲試用期過了，如何繼續購買存儲？',
  Renewal22: '無需單獨購買，我們將根據您使用的存儲空間，換算並扣取對應的GPU時長，每天結算扣取一次。',
  Renewal23: '如果賬號數量不足，如何繼續增購賬號數量？',
  Renewal24: '無需單獨增購，我們會根據您使用的賬號數量，換算並扣取對應的GPU時長，每天結算扣取一次。',
  Renewal25: '我的GPU時長消耗扣費規則是什麽？',
  Renewal26: "GPU\u6642\u9577\u7684\u6D88\u8CBB\u6263\u9664\u9805\u76EE\u5305\u542B\u4E09\u90E8\u5206\u5167\u5BB9\uFF1A <br>\n  (\uFF11) \u751F\u6210\u5716\u7247\u6263\u8CBB\u898F\u5247\uFF1A\u6839\u64DA\u6BCF\u5F35\u5716\u7247\u751F\u6210\u6642\u6240\u6D88\u8017\u7684GPU\u6642\u9593\u6263\u9664\u5C0D\u61C9\u4E3B\u8CEC\u865F\u4E0B\u7684\u6642\u9577\uFF0C\u5BE6\u6642\u6263\u8CBB\u3002<br>\n  (\uFF12) \u5B58\u5132\u6263\u8CBB\u898F\u5247\uFF1A\u5B58\u5132\u91C7\u7528\u6309\u5929\u6536\u8CBB\uFF0C1G\u5B58\u5132\u7B49\u65BC5\u79D2GPU\u6BCF\u5929\u3002\u6BCF\u592900:00\u9EDE\u7D50\u7B97\u6263\u53D6\u4E00\u6B21\uFF0C\u8D85\u904E\u4F46\u4E0D\u6EFF1G\uFF0C\u6309\u71671G\u6263\u8CBB\u3002<br>\n  (\uFF13) \u8CEC\u865F\u6263\u8CBB\u898F\u5247\uFF1A\u8CEC\u865F\u91C7\u7528\u4E00\u6B21\u6027\u6536\u8CBB\uFF0C\u7576\u8D85\u904E\u8D08\u9001\u7684\u8CEC\u865F\u6578\u91CF\u6642\uFF0C\u984D\u5916\u65B0\u589E\u7684\u8CEC\u865F\uFF0C1\u500B\u7B49\u65BC1\u5C0F\u664240\u5206GPU\u6642\u9577\uFF0C\u6BCF\u5929\u6E05\u7B97\u6642\u4E00\u6B21\u6027\u6263\u96641\u8CEC\u865F\u5C0D\u61C9\u6642\u9577\u3002",
  Renewal27: '不同檔位的套餐可以疊加嗎？',
  Renewal28: "\u53EF\u4EE5\u758A\u52A0\u3002\u758A\u52A0\u898F\u5247\u70BA\uFF1A<br>\n  (\uFF11) GPU\u6642\u9577\uFF1A\u6703\u6839\u64DA\u8CFC\u8CB7\u91D1\u984D\u9032\u884C\u758A\u52A0\u3002<br>\n  (\uFF12) \u4F01\u696D\u8CEC\u865F\u6578\u91CF\uFF1A\u540C\u6A94\u4F4D\u5957\u9910\u8CFC\u8CB7\u6642\uFF0C\u8D08\u9001\u6578\u91CF\u4E0D\u9032\u884C\u758A\u52A0\uFF0C\u5347\u7D1A\u5957\u9910\u6A94\u4F4D\u6642\uFF0C\u6309\u7167\u7528\u6236\u7576\u524D\u6240\u5C6C\u5957\u9910\u6A94\u4F4D\u9032\u884C\u5DEE\u984D\u8D08\u9001\uFF0C\u8CFC\u8CB7\u5F8C\u7ACB\u5373\u751F\u6548\u3002<br>\n  (\uFF13) \u8D08\u9001\u5B58\u5132\uFF1A\u540C\u6A94\u4F4D\u5957\u9910\u8CFC\u8CB7\u6642\uFF0C\u8D08\u9001\u5B58\u5132\u4E0D\u9032\u884C\u758A\u52A0\uFF0C\u5347\u7D1A\u5957\u9910\u6A94\u4F4D\u6642\uFF0C\u6309\u7167\u7528\u6236\u7576\u524D\u6240\u5C6C\u5957\u9910\u6A94\u4F4D\u9032\u884C\u5DEE\u984D\u8D08\u9001\uFF0C\u8CFC\u8CB7\u5F8C\u7ACB\u5373\u751F\u6548\u3002",
  Renewal29: '如何升級套餐檔位？',
  Renewal30: "\u6839\u64DA\u8CEC\u6236\u6B77\u53F2\u8CFC\u8CB7\u91D1\u984D\u81EA\u52D5\u9032\u884C\u5957\u9910\u5347\u7D1A:<br>\n  (\uFF11) \u6B77\u53F2\u8CFC\u8CB7\u91D1\u984D\u572810W\u4EE5\u5167\u4EAB\u53D7\u57FA\u790E\u7248\u5957\u9910\u6B0A\u76CA\uFF0C\u8D08\u9001\u6C38\u4E45\u4F01\u696D\u6578\u91CF50\u500B\uFF0C3\u500B\u6708\u8A66\u7528\u5B58\u513280G<br>\n  (\uFF12) \u6B77\u53F2\u8CFC\u8CB7\u91D1\u984D\u572810W-30W\u4EAB\u53D7\u521D\u7D1A\u7248\u5957\u9910\u6B0A\u76CA\uFF0C\u8D08\u9001\u6C38\u4E45\u4F01\u696D\u6578\u91CF100\u500B\uFF0C3\u500B\u6708\u8A66\u7528\u5B58\u5132500G<br>\n  (\uFF13) \u6B77\u53F2\u8CFC\u8CB7\u91D1\u984D\u572830W\u4EE5\u4E0A\u4EAB\u53D7\u5C08\u696D\u7248\u5957\u9910\u6B0A\u76CA\uFF0C\u8D08\u9001\u6C38\u4E45\u4F01\u696D\u6578\u91CF500\u500B\uFF0C3\u500B\u6708\u8A66\u7528\u5B58\u51321T",
  Renewal31: '支付訂單：',
  Renewal32: '購買詳情',
  Renewal33: '實付金額',
  Renewal34: '選擇支付方式：',
  Renewal35: '去支付寶支付',
  Renewal36: '微信支付',
  Renewal37: '生成二維碼',
  Renewal38: '支付請求處理中，請稍後...',
  Renewal39: '支付成功',
  Renewal40: 'GPU套餐包',
  Renewal41: '訂單編號',
  Renewal42: '付款時間',
  Renewal43: '完成',
  Renewal44: '贈',
  Purchase1: '購買歷史',
  Purchase2: '訂單編號',
  Purchase3: '購買套餐',
  Purchase4: '訂單金額',
  Purchase5: '賬號數量',
  Purchase6: 'GPU時長',
  Purchase7: '存儲空間',
  Purchase8: '購買日期',
  Purchase9: '規則詳情',
  Purchase10: '基礎版',
  Purchase11: '專業版',
  Purchase12: '高級版',
  Purchase13: '客戶等級',
  Purchase14: '服務項目',
  Purchase15: '服務計價方式',
  Purchase16: '服務規則',
  Purchase17: '贈送福利',
  Purchase18: '同等級內贈送福利不會疊加，升級等級後會進行差額贈送',
  Purchase19: '費用標準',
  Purchase20: '請選擇維度',
  Purchase21: 'GPU運算時長統計維度',
  Purchase22: '圖片統計維度',
  Purchase23: '個',
  DevConfig1: '端點',
  DevConfig2: '事件訂閱',
  DevConfig3: '秘鑰',
  DevConfig4: '任務完成',
  DevConfig5: '任務取消',
  DevConfig6: '保存',
  DevConfig7: '重置',
  DevConfig8: '端點不能爲空',
  DevConfig9: '秘鑰不能爲空',
  DevConfig10: '保存成功!',
  DevConfig11: '保存失敗!',
  DevConfig12: '請輸入端點',
  DevConfig13: '請輸入秘鑰'
};