var render = function render() {
  var _vm$companyInfo;
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "custom-messagge-dialog add-user-dialog",
    attrs: {
      title: _vm.$t("Account40"),
      visible: _vm.isShowDialog,
      "close-on-click-modal": false,
      "destroy-on-close": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShowDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    staticClass: "custom-form",
    attrs: {
      "label-position": "top",
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "".concat(_vm.$t("Account41", {
        num: ((_vm$companyInfo = _vm.companyInfo) === null || _vm$companyInfo === void 0 ? void 0 : _vm$companyInfo.left_emp) || 0
      })),
      prop: "channel_no"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: this.$t("inputPls", {
        type: _vm.$t(_vm.selectType)
      })
    },
    model: {
      value: _vm.formData.channel_no,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "channel_no", $$v);
      },
      expression: "formData.channel_no"
    }
  }, [_c("el-select", {
    attrs: {
      slot: "prepend",
      "popper-class": "custom-select"
    },
    on: {
      change: _vm.handleInputTypeChange
    },
    slot: "prepend",
    model: {
      value: _vm.selectType,
      callback: function callback($$v) {
        _vm.selectType = $$v;
      },
      expression: "selectType"
    }
  }, [_c("el-option", {
    attrs: {
      label: _vm.$t("Phone"),
      value: "Phone"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: _vm.$t("Email"),
      value: "Email"
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.selectType === "Email" ? _c("el-form-item", {
    attrs: {
      label: "".concat(_vm.$t("InitPassword")),
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      "show-password": "",
      placeholder: _vm.$t("EmailPasswordHandler")
    },
    model: {
      value: _vm.formData.password,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formData.password"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "btns",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("Confirm")))])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };