var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "dialog custom-dialog",
    attrs: {
      title: _vm.$t("Account37"),
      visible: _vm.isShowDialog,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShowDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    staticClass: "custom-form",
    attrs: {
      "label-position": "top",
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "".concat(_vm.$t("Account37"), " (").concat(_vm.$t("Minute"), ")"),
      required: "",
      prop: "expend_limit"
    }
  }, [_c("el-input-number", {
    attrs: {
      placeholder: _vm.$t("Account39"),
      controls: false,
      min: 0,
      max: 1440,
      step: 1,
      precision: 0
    },
    model: {
      value: _vm.formData.expend_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "expend_limit", $$v);
      },
      expression: "formData.expend_limit"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-btn",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("Confirm")))])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };