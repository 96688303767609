export default {
  ErrorCode_20306: 'Avatar modification limit reached',
  ErrorCode_80044: 'Insufficient equity',
  ErrorCode_30005: 'The uploaded image is in violation of the law',
  ErrorCode_70000: 'Operation failed',
  ErrorCode_70001: 'Generation count exceeded daily limit',
  ErrorCode_70002: 'Task waiting queue is full',
  ErrorCode_30000: 'Upload failed',
  ErrorCode_30001: 'File upload timeout',
  ErrorCode_30002: 'Failed to request file',
  ErrorCode_30003: 'File size exceeded limit',
  ErrorCode_30004: 'File parsing failed',
  ErrorCode_30008: 'Number of model images must be greater than 1',
  ErrorCode_30009: 'Model name duplicate',
  ErrorCode_30011: 'Model name length exceeds limit',
  ErrorCode_30029: 'Failed to export xlsx data',
  ErrorCode_30030: 'No data in list',
  ErrorCode_10408: 'Nickname not legal',
  ErrorCode_20302: 'Nickname already exists',
  ErrorCode_20305: 'Nickname modification limit reached',
  ErrorCode_20001: 'Account does not exist',
  ErrorCode_20004: 'Your mailbox has been disabled',
  ErrorCode_20005: 'Wrong password',
  ErrorCode_20100: 'Invalid mailbox',
  ErrorCode_150001: 'Face not recognized',
  ErrorCode_500601: 'This account is already an enterprise, cannot add',
  ErrorCode_500602: 'No more resources to allocate',
  ErrorCode_500600: 'Insufficient number of enterprise sub-accounts',
  ErrorCode_500603: 'Account already exists',
  EnterpriseEdition: 'C&E',
  Home: 'Home',
  AcconutManage: 'Account',
  Account: 'account',
  Password: 'password',
  Analysis: 'Data analysis',
  APIInfo: 'API Info',
  RenewalPackage: 'Renewal package',
  Purchase: 'Purchase history',
  PurchaseHistory: 'Purchase history',
  Config: 'Development Configuration',
  Weclome: 'Welcome',
  WeclomeTo: 'to the backstage of Haiyi Enterprise',
  Search: 'Search',
  Reset: 'Reset',
  Status: 'Status',
  Handle: 'Operation',
  CreatedTime: 'Creation time',
  Disabled: 'Disabled',
  Normal: 'Normal',
  Enable: 'Enable',
  Selected: 'Selected',
  Page: 'Page',
  Items: 'Pieces',
  Go: 'Go',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Prompt: 'Prompt',
  Created: 'Created',
  Add: 'Add',
  HandleSuccess: 'Operation successful',
  HandleError: 'The operation failed',
  ExitFullscreen: 'Exit full Screen',
  FullScreen: 'Full screen window',
  ChangePassword: 'Change password',
  SignOut: 'Sign out',
  CustomSuccess: '{type} success',
  CustomError: '{type} failed',
  Day: 'Day',
  Hours: 'Hours',
  Minute: 'Minutes',
  Second: 'Seconds',
  PayPal: 'PayPal',
  Payermax: 'Payermax',
  Account1: 'Account',
  Account2: 'Please enter user nickname',
  Account3: 'Third-party account',
  Account4: 'Please enter a third-party account',
  Account6: 'Please select a start time',
  Account7: 'Please select an end time',
  Account8: 'Add in batches',
  Account9: 'Batch export',
  Account10: 'User ID',
  Account11: 'User Nickname',
  Account12: 'User account',
  Account13: 'Cumulative GPU consumption (hours)',
  Account14: 'GPU consumed today (hours)',
  Account15: 'Cumulative storage usage (MB)',
  Account16: 'Set as admin',
  Account17: 'reset Password',
  Account18: 'New users',
  Account19: 'Please enter the number of new additions: (Currently, {num} can be created for free)',
  Account20: 'Please enter the number of accounts created',
  Account21: 'Account created successfully',
  Account22: 'Account creation failed',
  Account23: 'Creating a free account exceeds the limit, please top up!',
  Account24: 'to recharge',
  Account25: 'Password format is incorrect',
  Account26: 'Whether {type}[{name}] has administrative rights',
  Account27: 'Please enter a new password (account: [{name}])',
  Account28: 'The new quantity entered is incorrect',
  Account29: 'Please enter the creation quantity',
  Account30: 'A maximum of 500 accounts can be generated each time!',
  Account31: 'After adding a sub-account, the sub-account will be automatically exported, please save it properly!',
  Account32: 'Cancel management',
  Account33: 'set as admin',
  Account34: 'Confirm {type} accounct:【{name}】?',
  Account35: 'Password length is 8~20 characters',
  Account36: 'Reset failed, please try again',
  Account37: 'Daily GPU consumption limit',
  Account38: 'Only numbers can be entered',
  Account39: 'Please enter the daily GPU consumption (minutes)',
  Account40: 'Add sub account',
  Account41: 'Currently, {num} can be created for free',
  Account42: 'Allocate GPU duration',
  Account43: 'Remaining GPUs of the enterprise: {text}',
  Account44: 'After submitting the GPU duration allocation, it cannot be modified. Please fill it out carefully',
  Account45: 'Allocate GPU',
  Account46: 'Initial password',
  Account47: 'GPU consumption limit',
  Email: 'Email',
  Phone: 'Phone Number',
  newAdd: 'Newly Added',
  inputPls: 'Please enter {type}',
  inputWarning: 'Please enter the correct {type}',
  PasswordANATCS: "Password are not allowed to contain spaces",
  InitPassword: 'Initial password',
  EmailPasswordHandler: 'Existing account? Will not be overwrite',
  Analysis1: 'Usage statistics',
  Analysis3: 'Cumulative usage time of GPU',
  Analysis4: 'Cumulative GPU consumption including storage/account conversion',
  Analysis5: 'Cumulative storage usage',
  Analysis7: 'Remaining statistics',
  Analysis8: 'GPU remaining usage time',
  Analysis9: 'The total remaining balance of enterprise version Haiyi login and API calls',
  Analysis11: 'Select query month',
  Analysis12: 'Average daily usage time:',
  Analysis13: 'Average daily storage usage:',
  Analysis14: 'Daily use',
  Analysis16: 'Duration',
  Analysis17: 'Storage',
  Analysis18: 'Day',
  API1: 'The document is being updated. To view the address, please click ',
  API2: 'here.',
  Renewal1: 'GPU duration renewal',
  Renewal2: 'Customer level',
  Renewal3: 'Gift account',
  Renewal4: 'Gift storage',
  Renewal5: '3 months trial',
  Renewal6: 'Charges',
  Renewal7: '￥/h',
  Renewal77: '$/h',
  Renewal8: 'Buy GPU hours',
  Renewal9: 'Piece',
  Renewal10: 'Complimentary GPU hours',
  Renewal11: 'Hours',
  Renewal12: 'Frequently Asked Questions about Renewal Packages',
  Renewal13: 'Calculating',
  Renewal15: 'Buy now',
  Renewal16: 'GPU {level} package {num} yuan/hour',
  Renewal166: 'GPU {level} package {num} USD/hour',
  Renewal18: 'Renewal FAQ',
  Renewal19: 'Do you support refunds?',
  Renewal20: 'No refunds are supported',
  Renewal21: 'If the free storage trial period has expired, how can I continue to purchase storage?',
  Renewal22: 'There is no need to purchase it separately. We will convert and deduct the corresponding GPU time based on the storage space you use, and deduct it once a day.',
  Renewal23: 'If the number of accounts is insufficient, how can I continue to purchase more accounts?',
  Renewal24: 'There is no need to purchase additional units separately. We will convert and deduct the corresponding GPU time based on the number of accounts you use, and deduct it once a day during settlement.',
  Renewal25: 'What are the deduction rules for my GPU time consumption?',
  Renewal26: "The consumption deduction item for GPU time consists of three parts: <br>\n  (\uFF11) Deduction rules for generating pictures: Deduct the time under the corresponding main account based on the GPU time consumed when generating each picture, and deduct fees in real time.<br>\n  (\uFF12) Storage deduction rules: Storage is charged on a daily basis, and 1G of storage equals 5 seconds of GPU per day. The settlement will be deducted once every day at 00:00. If the amount exceeds but is less than 1G, the fee will be deducted as 1G.<br>\n  (\uFF13) Account deduction rules: Accounts are charged one-time. When the number of gifted accounts is exceeded, one additional account is equal to 1 hour and 40 minutes of GPU time. The corresponding time of 1 account will be deducted at one time during daily settlement.",
  Renewal27: 'Can packages of different gears be superimposed?',
  Renewal28: "Can be superimposed. The superposition rules are:<br>\n  (\uFF11) GPU duration: will be superimposed according to the purchase amount.<br>\n  (\uFF12) Number of enterprise accounts: When purchasing packages of the same level, the gift quantity will not be superimposed. When upgrading the package level, the difference will be given according to the user's current package level, and it will take effect immediately after purchase.<br>\n  (\uFF13) Complimentary storage: When purchasing packages of the same level, the complimentary storage will not be superimposed. When upgrading the package level, the difference will be given as a gift based on the user's current package level, which will take effect immediately after purchase.",
  Renewal29: 'How to upgrade the package level?',
  Renewal30: "Automatic package upgrade based on account history purchase amount:<br>\n  (\uFF11) If the historical purchase amount is within 10W, you can enjoy the benefits of the basic package, 50 permanent enterprises, and a 3-month trial storage of 80G.<br>\n  (\uFF12) 30W to enjoy the benefits of the junior version package, 100 permanent enterprises, and 500G storage for a 3-month trial<br>\n  (\uFF13) If the historical purchase amount is above 30W, you can enjoy the rights and interests of the professional version of the package, as a gift of 500 permanent enterprises, and 1T of trial storage for 3 months",
  Renewal31: 'Payment order:',
  Renewal32: 'purchase details',
  Renewal33: 'The amount actually paid',
  Renewal34: 'Select Payment Method:',
  Renewal35: 'Go to Alipay to pay',
  Renewal36: 'WeChat Pay',
  Renewal37: 'Generate QR code',
  Renewal38: ' ayment request processing, please wait...',
  Renewal39: 'Payment successful',
  Renewal40: 'GPU package',
  Renewal41: 'Order number',
  Renewal42: 'Payment time',
  Renewal43: 'Finish',
  Renewal44: 'Gift',
  Purchase1: 'Purchase history',
  Purchase2: 'Order number',
  Purchase3: 'Buy package',
  Purchase4: 'Order amount',
  Purchase5: 'Number of accounts',
  Purchase6: 'GPU time',
  Purchase7: 'Storage',
  Purchase8: 'Date of purchase',
  Purchase9: 'Rule details',
  Purchase10: 'Base',
  Purchase11: 'Major',
  Purchase12: 'Advanced',
  Purchase13: 'Customer level',
  Purchase14: 'Service items',
  Purchase15: 'Service pricing method',
  Purchase16: 'Service rules',
  Purchase17: 'Give away benefits',
  Purchase18: 'Benefits given within the same level will not be superimposed, and the difference will be given after upgrading the level.',
  Purchase19: 'Fee standard',
  Purchase20: 'Please select a dimension',
  Purchase21: 'Statistical Dimensions of GPU Computing Time',
  Purchase22: 'Image Statistics Dimensions',
  Purchase23: '',
  DevConfig1: 'Endpoint',
  DevConfig2: 'Event Subscription',
  DevConfig3: 'Secret key',
  DevConfig4: 'mission completed',
  DevConfig5: 'Task Cancel',
  DevConfig6: 'Save',
  DevConfig7: 'Reset',
  DevConfig8: 'Endpoint cannot be empty',
  DevConfig9: 'The secret key cannot be empty',
  DevConfig10: 'Saved successfully!',
  DevConfig11: 'Save failed!',
  DevConfig12: 'Please enter endpoint',
  DevConfig13: 'Please enter the secret key'
};