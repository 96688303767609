import fetch from '@/utils/fetch';
var publicPath = '/api/v1/manager/emp';

/**
获取账户列表
*/
export function getAccountList(data) {
  return fetch({
    url: publicPath + '/list',
    method: 'post',
    data: data
  });
}

/**
更新员工角色
*/
export function updateAccountRole(data) {
  return fetch({
    url: publicPath + '/role/update',
    method: 'post',
    data: data
  });
}

/**
更新员工状态
*/
export function updateAccountStatus(data) {
  return fetch({
    url: publicPath + '/status/update',
    method: 'post',
    data: data
  });
}
/**
重置员工密码
*/
export function resetPasw(data) {
  return fetch({
    url: publicPath + '/psd/reset',
    method: 'post',
    data: data
  });
}
export function AddAccount(data) {
  return fetch({
    url: publicPath + '/add',
    method: 'post',
    data: data
  });
}
/**
批量新增账号
*/
export function batchAddAccount(data) {
  return fetch({
    url: publicPath + '/add/batch',
    method: 'post',
    data: data
  });
}

/**
限制员工每日消耗算力
*/
export function setLimitHttp(data) {
  return fetch({
    url: publicPath + '/set-config',
    method: 'post',
    data: data
  });
}

/**
 * 设置员工消耗算力
 */
export function setComputeLimit(data) {
  return fetch({
    url: publicPath + '/compute-limit',
    method: 'post',
    data: data
  });
}